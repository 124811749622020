import { template as template_bd3ad04e72d945aa8e3f2458dded7049 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_bd3ad04e72d945aa8e3f2458dded7049(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
