import { template as template_89956670cf784c7395773a9804ed776f } from "@ember/template-compiler";
const WelcomeHeader = template_89956670cf784c7395773a9804ed776f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
